import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldWillow: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Willow?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_willow.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Willow?</h1>
          <h2>
            Not sure if pulling Willow is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>10/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="willow"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Willow is one of the strongest Damage Dealers in the game, dealing
          high damage per turn in [Poison] teams by converting stacks of it into
          [Hag’s Bane], which does increasingly more damage as it's stacked over
          the turns. Despite her power, whether you should pull her or not
          relies on one thing: Do you have Tuesday?
        </p>
        <p>
          Without Tuesday and her [Fear’s Cradle] buff, Willow’s damage plummets
          so dramatically that she can’t even compete with her 1.2 predecessor
          Jessica as it allows [Poison] to Crit, which it normally cannot.
        </p>
        <SectionHeader title="Review" />
        <p>
          Willow is a 6-star Arcanist of the Plant Afflatus. She is the second
          cornerstone of the Poison team, using her unique [Poison] adjacent
          debuff, she destroys any long-form content. Note that she gets
          significantly weaker if not paired with Tuesday.
        </p>
        <p>
          Her kit revolves around the [Hag's Bane] debuff. This is a debuff that
          stacks on itself (think of the [Burn] debuff) and is considered
          [Poison]. At the end of the round, it increases its own DMG Dealt
          based on how often it's triggered per round (up to 4 times per round,
          max 10 stacks). Of course, Willow has ways of triggering this debuff
          more than once per round.
        </p>
        <p>
          Let's start with how she generates [Hag's Bane]. For this she uses
          "Hag's Advice", which converts existing stacks of [Poison] into [Hag's
          Bane]. To do this you need sources of [Poison] such as Tuesday or
          Sotheby, as the only way Willow can generate [Poison] is with her
          Insight 1.
        </p>
        <p>
          On the other hand we have "Hag's Gratitude", which consumes [Poison]
          stacks and triggers [Hag's Bane] 1 time (to work towards the goal of
          triggering its multiple times per round).
        </p>
        <p>
          Her Ultimate "In The Fire, In The Shadow" is the most important part
          of her kit. It enters her into the [Ancient Ritual] channel. While
          active, [Hag's Bane] is considered to have triggered an amount of
          times per round even if it hasn't. In addition, to keep the channel
          active, Willow consumes around 4+ Moxie per round and inflicts [Hag's
          Bane] on all enemies equal to the consumed amount of Moxie. If not
          enough Moxie is available to consume, the channel ends and she casts
          “Ending Pose” which is a mass attack that scales its damage with the
          amount of [Hag's Bane] on the target.
        </p>
        <h5>Gameplay </h5>
        <p>Willow pushes [Poison] into a meta position, with a caveat.</p>
        <p>
          If you don't have Tuesday, don't even bother playing Willow. Without
          the combo, Willow's damage drops so dramatically she gets beaten out
          by Jessica.
        </p>
        <p>
          Outside of that, there's a lot to unpack. Right off the bat, [Hag's
          Bane] has a great modifier, layers on itself, and an insane modifier
          once the triggers are fully stacked. This is easy to do if you keep
          [Ancient Ritual] active, which is the primary gimmick and problem
          surrounding her kit. The longer you keep it active, the stronger you
          make [Hag's Bane] and the harder “Ending Pose” will hit; do it wrong
          (i.e. ending early or miss timing it) and your damage plummets.
        </p>
        <p>
          Do it right and you're hitting your enemies with ATK*50% Genesis DMG
          (per stack of [Hag's Bane]). As you'll be running, on average, 7 or
          more stacks, it'll be stacking quite considerably.
        </p>
        <p>
          This is ALL under the presumption that Tuesday is present in your
          team. The “Ending Pose” cast is potentially strong, but a majority of
          the damage output is tied to the [Hag's Bane] triggers per round. As
          it is considered [Poison], it cannot normally Crit. To help this,
          Tuesday has an effect called [Fear's Cradle] that allows [Poison] to
          Crit, which also affects [Hag's Bane]. This is one of the main reasons
          that Willow without Tuesday is almost non-functional.
        </p>
        <p>
          The downside to this is [Ancient Ritual]. Willow's Insight 3 makes it
          so that she gains extra Moxie while in the channel, meaning that
          you'll need to dedicate at least 2 AP per round to casting/merging
          with her in order to upkeep it. Tuesday helps a little with [Room
          Service] allowing you to overcap on Moxie, but she brings the problem
          of needing to upkeep her Array, which makes you required to dedicate
          1-2 AP per round to casting/merging with her. This gives VERY little
          room for other units to act, forcing you into picking units with a
          relatively AP-positive playstyle.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    One of the best Damage Dealers in the game; insanely high
                    DPT potential through stacked [Hag's Bane]. Possibly the one
                    Damage Dealer that can get anywhere close to Windsong in
                    terms of damage at the time of writing this.
                  </li>
                  <li>
                    Completely bypasses DEF types due to focus on Genesis DMG.
                  </li>
                  <li>
                    {' '}
                    Deals mostly unsourced damage, ignoring most Counter and
                    Riposte effects on enemies.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Extremely reliant on Tuesday allowing [Poison] to Crit.
                  </li>
                  <li>
                    Extremely AP-negative during the [Ancient Ritual] channel.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Completely fine at P0.</p>
        <p>
          If you’ve been waiting eagerly to complete that [Poison] team and seek
          to invest into Willow for this reason there are a few Portraits to
          further optimize her damage. In our opinion, you’re looking at
          Portrait 1 and 2 here (luckily they’re the early ones).
        </p>
        <p>
          Portrait 1 increases the Genesis DMG Dealt of [Hag’s Bane] from 20% of
          ATK to 24%. This may seem minor, but it ramps over the course of a
          fight especially as you keep the debuff applied.{' '}
        </p>
        <p>
          Portrait 2 increases the Genesis DMG Dealt of the [Poison] inflicted
          by her Insight and, more importantly, increases the damage ramp of
          [Hag’s Bane] from 40% per trigger to 45%.
        </p>
        <p>
          Of course, Portrait 5 is rather strong (further increases the damage
          ramp to 50%), but it’s by no means necessary to make Willow a strong
          character.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Poison Team, Poison Team, Poison Team. Anything that inflicts [Poison]
          or is somehow supported by whatever Tuesday does without eating too
          much AP does well. Normally, you’re running Vila as Healer for [Song
          of Passion], however, if you’re struggling to stay alive, a healbot
          Sotheby with Euphoria works just as well!{' '}
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="tuesday"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="sotheby"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="j"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="kakania"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies: N/A</p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Willow:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="her-mournful-face"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="offbeat-strides"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Resonance" />
        <h6>R10 Build (Equibalance Recommended)</h6>
        <StaticImage
          src="../../../images/reverse/generic/willow_r_1.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <div className="team-box">
          <h5 className="star">
            Toxic Therapists
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="willow"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="tuesday"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Kakania</strong>
              </li>
              <li>
                Poison is a very powerful archetype, wielding several of the
                strongest characters in the game. Despite this it is lacking in
                variety options compared to its direct competitor: Follow-Up.
                This doesn’t hurt the team though, as when the characters that
                feature in it come together, it does just as well as Follow-Up.
              </li>
              <li>
                Tuesday is a must here. Her [Fear’s Cradle] massively boosting
                [Poison] application and allowing it to Crit is key in making
                any Poison-oriented team work, especially this one, as [Hag’s
                Bane] is considered a [Poison] effect. In addition, Tuesday’s
                [Room Service] extends the time that you can keep Willow’s
                [Ancient Ritual] channel active by allowing you to overcap on
                Moxie.
              </li>
              <li>
                But it doesn’t stop there. Kakania provides both AFK DMG
                Reduction and Genesis DMG output (with some healing on the
                side). This not only allows Willow+Tuesday to eat all her
                would-be AP, it benefits from Tuesday’s +Genesis DMG Taken
                debuff as well, and [Solace] as an ATK buff scales well with
                [Poison].
              </li>
              <li>
                Vila or Euphoria Sotheby are your healer options here. It’s
                pretty simple: if you’re going for damage you run Vila, if you
                need survivability you run healbot Euphoria Sotheby. If you
                happen to want to run J for the extra AP-positivity, a standard
                J+Babel combo works as well due to providing AP-positive debuff
                application.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldWillow;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Willow? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Willow is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
